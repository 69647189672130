import * as React from "react"
import questionprimaryicn from '../../assets/img/question-primary-icn.svg';

const DidYouKnow = (props) => {
	return (
		<div class="ctas tertiary">
			<div class="tertiary-cta">
				<img src={questionprimaryicn} alt="" width="97" height="97" />
				<div class="content">
					<p class="head"><b>Did you Know?</b></p>
					<p>{props.children}</p>
				</div>
			</div>
		</div>
	)
}

export default DidYouKnow;
